<template>
  <div class="container py-4">
    <img src="@/assets/img/tidsmaskin.jpg" alt="Tidskapseln.se Logo" class="img-fluid mb-4" style="max-width: 30%;">
    <h3 class="vit-text mellanrum">{{ welcomeMessage }} {{ userName }}</h3>
    <!-- Uppdateringsnotis -->
    <div v-if="isUpdateAvailable" class="update-banner">
      
      <p>En ny version är tillgänglig.</p>
      <button @click="refreshApp">Ladda om app</button>
    </div>

    <div class="list-group">
      <div v-for="minne in minnen" :key="minne.id"
        class="list-group-item d-flex justify-content-between align-items-center minne-item">
        <router-link :to="`/visaminnen/${minne.id}`" class="flex-grow-1 me-3 link-link">
          <div>
            <h5 class="mb-1 minne-title">{{ minne.rubrik }}</h5>
            <small>{{ minne.datum }}</small>
          </div>
        </router-link>
        <router-link :to="`/editminne/${minne.id}`" class="link-link">
          <span class="bi bi-pencil-square icon-spacing"></span> <!-- Bootstrap "edit" ikon -->
        </router-link>
        <router-link :to="`/editimage/${minne.id}`" class="link-link">
          <span class="bi bi-card-image"></span> <!-- Bootstrap "edit" ikon -->
        </router-link>
      </div>
    </div>
  </div>
  <div class="container py-4">
    <!-- Din befintliga kod här -->

    <!-- Bootstrap grid för att justera bredden på add-minnen -->
    <div class="row">
      <div class="col-md-6 offset-md-3 bakgrund">
        <add-minnen></add-minnen>
      </div>
    </div>
  </div>
</template>
  
<script>
import AddMinnen from '@/components/AddMinnen.vue';
import { getAuth } from 'firebase/auth';
import { db } from '@/firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';

export default {
  name: 'MinnensLista',
  components: {
    AddMinnen
  },
  data() {
    return {
      minnen: [], // Array som kommer att fyllas med minnen från Firestore
      isUpdateAvailable: false, // Flagga för att spåra tillgänglig uppdatering
      welcomeMessage: '', // Ny dataegenskap för välkomstmeddelande
    };
  },
  computed: {
    userName() {
      const auth = getAuth();
      if (auth.currentUser) {
        const fullName = auth.currentUser.displayName;
        const firstName = fullName.split(' ')[0]; // Dela upp namnet och välj första delen (förnamnet)
        return firstName;
      }
      return '';
    }
  },
  async created() {
    this.welcomeMessage = this.getRandomWelcomeMessage(); // Hämta ett slumpmässigt välkomstmeddelande
    const auth = getAuth();
    if (auth.currentUser) {
      try {
        const userMinnenQuery = query(
          collection(db, 'minnen'),
          where('userId', '==', auth.currentUser.uid)
        );
        const sharedMinnenQuery = query(
          collection(db, 'minnen'),
          where('sharedUsers', 'array-contains', auth.currentUser.email)
        );

        const [userMinnenSnapshot, sharedMinnenSnapshot] = await Promise.all([
          getDocs(userMinnenQuery),
          getDocs(sharedMinnenQuery)
        ]);

        const userMinnen = userMinnenSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const sharedMinnen = sharedMinnenSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        this.minnen = [...userMinnen, ...sharedMinnen]
          .sort((a, b) => b.datum.localeCompare(a.datum));
      } catch (error) {
        console.error('Ett fel uppstod vid hämtning av minnen:', error);
      }
    }

    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js').then(reg => {
        reg.addEventListener('updatefound', () => {
          const newWorker = reg.installing;
          newWorker.addEventListener('statechange', () => {
            if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
              this.isUpdateAvailable = true;
            }
          });
        });
      });
    }
  },
  methods: {
    refreshApp() {

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
          for (let registration of registrations) {
            registration.update().then(() => {
              if (registration.waiting) {
                // Informera användaren om att en ny version är tillgänglig
                this.isUpdateAvailable = true;
              }
            });
          }
        });
      }
    },
    getRandomWelcomeMessage() {
      const welcomeMessages = [
        "Välkommen till dina minnen!",
        "Välkommen",
        "Hälsningar från Tidskapseln!",
        "Ny minnen på gång?",
        "Välkommen",
        "Hej där",
        "Vad roligt att träffa dig",
        "Trevligt att ses igen",
        
        "Härligt att ha dig här",
        "Hej och hå",
        "Hur mår du?",
        "Det är alltid trevligt med ditt sällskap",
        "Hej, hej",
        "Ha en fantastisk dag",
        "God natt",
        "Vilken ära att ha dig här",
        "Välkommen tillbaka",
        "Hej, det var länge sedan sist",
        "Vad har du haft för dig",
        "Så trevligt att ses",
        "God förmiddag",
        "Du är alltid välkommen",
        "Hur har din dag varit",
        "Välkommen till vårt sällskap",
        "Hej på dig",
        "Hälsningar",
        "Vad är senaste nytt?",
        "Det är alltid en glädje att träffa dig",
        "Hej igen",
        "Trevligt att du kom",
        "Ha en underbar kväll",
        "Vad har du planerat",
        "Välkommen tillbaka till verkligheten",
        "Hej och välkommen",
        "Hoppas att du har haft en bra dag",
        "Det är alltid en fest när du är här",
        "Trevligt att ses på nytt",
        "Välkommen till vår lilla tillställning",
        "Hej, låt oss ha kul",
        "Härligt att träffa dig igen",
        "Du är alltid så välkommen",
        "Hoppas du har en underbar tid här",
        "Trevligt att träffa dig",
        "Så kul att se dig igen",
        "Hej där, kompis",
        "Vad har du på gång?",
        "Ha en fantastisk kväll",
        "Hoppas allt är bra med dig",
        "Det är alltid en glädje att vara i ditt sällskap",
        "Härligt att ha dig här med oss",
        "Välkommen till din värld",
        "Hoppas du har en fin dag",

      ];
      const randomIndex = Math.floor(Math.random() * welcomeMessages.length);
      return welcomeMessages[randomIndex];
    },
  },
}

</script>





  
<style scoped>
/* Anpassad stil för redigeringsikonen */
.link-link {
  color: black;
  /* Svart färg för att matcha den vanliga texten */
  text-decoration: none;
  /* Tar bort understrykningen */
}

/* Lägg till hover-effekt om önskat */
.link-link:hover {
  text-decoration: none;
  color: #565656;
  /* Ljusare svart eller annan färg vid hover */
}

body {
  font-family: 'Roboto', sans-serif;
}

.minne-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  /* För en fetare titel */
}

.minne-item {
  margin-bottom: 1px;
  /* Lägger till 5px marginal under varje minne */
}

.vit-text {
  color: #4b4848;
}
.mellanrum {
  margin-bottom: 40px;
}
.bakgrund{
background-color: #e5e2e2;
border-radius: 10px;
}
.update-banner {
  background-color: orange;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
}
.icon-spacing {
  margin-right: 10px; /* Lägger till 10px mellanrum till höger om varje ikon */
}
</style>
  

  