<template>
    <div class="container py-4">
      <h2>Redigera Bilder</h2>
      <div v-if="loading">Laddar...</div>
      <div v-else>
        <div class="bilder-lista">
          <div v-for="(bild, index) in bilder" :key="index" class="bild-container">
            <img :src="bild" alt="Minnesbild" class="minnes-bild">
            <button @click="deleteImage(bild, index)" class="delete-btn">Ta bort</button>
          </div>
        </div>
        <input type="file" @change="uploadImage">
      </div>
    </div>
    </template>
    
    <script>
    import { db, storage } from '@/firebaseConfig';
    import { doc, getDoc, updateDoc } from 'firebase/firestore';
    import { ref as storageRef, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
    import { useRouter, useRoute } from 'vue-router';
    
    export default {
      name: 'EditImage',
      setup() {
        const router = useRouter();
        const route = useRoute();
        const minneId = route.params.id;
    
        return { router, minneId };
      },
      data() {
        return {
          bilder: [],
          loading: false
        };
      },
      async created() {
        this.fetchBilder();
      },
      methods: {
        async fetchBilder() {
          this.loading = true;
          try {
            const minneDocRef = doc(db, 'minnen', this.minneId);
            const minneDoc = await getDoc(minneDocRef);
            if (minneDoc.exists()) {
              this.bilder = minneDoc.data().bilder || [];
            } else {
              console.error('Minne finns inte!');
              this.router.push('/listaminnen');
            }
          } catch (error) {
            console.error('Ett fel uppstod:', error);
          } finally {
            this.loading = false;
          }
        },
        async uploadImage(event) {
          const file = event.target.files[0];
          if (!file) return;
          this.loading = true;
          const storageReference = storageRef(storage, `bilder/${this.minneId}/${file.name}`);
          const uploadTask = uploadBytesResumable(storageReference, file);
  
          uploadTask.on(
            'state_changed',
            () => {
              // Hantera uppladdningsprocessen
            },
            (error) => {
              console.error('Uppladdningsfel:', error);
              this.loading = false;
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              this.bilder.push(downloadURL);
              await this.updateBilderFirestore();
              this.loading = false;
            }
          );
        },
        async deleteImage(bildUrl, index) {
          const confirmDelete = confirm('Är du säker på att du vill ta bort denna bild?');
          if (!confirmDelete) return;
  
          this.loading = true;
          try {
            // Ta bort bild från Firebase Storage
            let imageRef = storageRef(storage, bildUrl);
            await deleteObject(imageRef);
  
            // Ta bort bild från bilder array och uppdatera Firestore
            this.bilder.splice(index, 1);
            await this.updateBilderFirestore();
          } catch (error) {
            console.error('Raderingsfel:', error);
          } finally {
            this.loading = false;
          }
        },
        async updateBilderFirestore() {
          try {
            const minneDocRef = doc(db, 'minnen', this.minneId);
            await updateDoc(minneDocRef, { bilder: this.bilder });
          } catch (error) {
            console.error('Ett fel uppstod vid uppdatering av bilder:', error);
          }
        }
      }
    };
    </script>
    
    <style scoped>
    .bilder-lista {
      display: flex;
      flex-wrap: wrap;
    }
    .bild-container {
      margin: 10px;
      position: relative;
    }
    .minnes-bild {
      width: 100px;
      height: auto;
      border-radius: 5px;
    }
    .delete-btn {
      position: absolute;
      top: 0;
      right: 0;
    }
    </style>
  