<template>
    <div class="container py-4">
      <img src="@/assets/img/tidsmaskin.jpg" alt="Tidskapseln.se Logo" class="img-fluid mb-4" style="max-width: 15%;">
      <div class="row">
        <div class="col-sm">
        </div>
        <div class="col-sm">
          <h2>Dela mina minnen</h2>
          <form @submit.prevent="delaMinnen" class="d-flex mb-4">
            <input type="email" v-model="email" placeholder="Ange e-postadress" class="form-control me-2">
            <button type="submit" class="btn btn-primary">Lägg till</button>
          </form>
          <div v-if="sharedUsers.length" class="mt-4">
            <h4>Delar just nu med:</h4>
            <ul class="list-group">
              <li v-for="userEmail in sharedUsers" :key="userEmail" class="list-group-item d-flex justify-content-between align-items-center">
                {{ userEmail }}
                <button @click="removeSharedUser(userEmail)" class="btn btn-danger btn-sm">Ta bort</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm">
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { db, auth } from '@/firebaseConfig';
  import { doc, updateDoc, arrayUnion, arrayRemove, getDoc } from 'firebase/firestore';
  
  export default {
    name: 'DelaMinnen',
    data() {
      return {
        email: '',
        sharedUsers: []
      };
    },
    async created() {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        this.sharedUsers = userDoc.data().sharedUsers || [];
      }
    },
    methods: {
      async delaMinnen() {
        if (!this.email) {
          alert('Ange en giltig e-postadress');
          return;
        }
        try {
          const userDocRef = doc(db, 'users', auth.currentUser.uid);
          await updateDoc(userDocRef, {
            sharedUsers: arrayUnion(this.email)
          });
          this.sharedUsers.push(this.email);
          alert('Användare tillagd för att dela minnen!');
          this.email = '';
        } catch (error) {
          console.error('Ett fel uppstod:', error);
          alert('Ett fel uppstod. Försök igen.');
        }
      },
      async removeSharedUser(email) {
        try {
          const userDocRef = doc(db, 'users', auth.currentUser.uid);
          await updateDoc(userDocRef, {
            sharedUsers: arrayRemove(email)
          });
          this.sharedUsers = this.sharedUsers.filter(userEmail => userEmail !== email);
          alert('Användare borttagen från delningslistan!');
        } catch (error) {
          console.error('Ett fel uppstod:', error);
          alert('Ett fel uppstod. Försök igen.');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Stilar för att bredda formuläret */
  .col-sm {
    flex-grow: 1;
    max-width: 100%;
  }
  
  /* Lägg till stilar för att göra knapparna bredare om nödvändigt */
  .btn {
    white-space: nowrap; /* Förhindrar radbrytning */
  }
  </style>
  