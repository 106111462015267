<template>
  <div class="container py-4">
    <h2 class="mb-4">Redigera Minne</h2>
    <form @submit.prevent="updateMinne" class="needs-validation" novalidate>
      <div class="mb-3">
        <label for="datum" class="form-label">Datum</label>
        <input type="date" class="form-control" id="datum" v-model="datum" required>
        <div class="invalid-feedback">
          Ange ett datum.
        </div>
      </div>

      <div class="mb-3">
        <label for="rubrik" class="form-label">Rubrik</label>
        <input type="text" class="form-control" id="rubrik" v-model="rubrik" required>
        <div class="invalid-feedback">
          Ange en rubrik.
        </div>
      </div>

      <div class="mb-3">
        <label for="beskrivning" class="form-label">Beskrivning</label>
        <textarea class="form-control" id="beskrivning" rows="3" v-model="beskrivning" required></textarea>
        <div class="invalid-feedback">
          Ange en beskrivning.
        </div>
      </div>

      <div class="mb-3">
        <label for="deltagare" class="form-label">Deltagare</label>
        <input type="text" class="form-control" id="deltagare" v-model="deltagare" required>
        <div class="invalid-feedback">
          Ange deltagare.
        </div>
      </div>
      <!-- Sektion för att hantera delade e-postadresser -->
      <div class="mb-3 darkerbackground">
        <label for="sharedUsers" class="form-label">Dela med (gmail-adresser)</label>
        <div v-for="(email, index) in sharedUsers" :key="index" class="d-flex mb-2">
          <input type="text" class="form-control me-2" v-model="sharedUsers[index]">
          <button @click.prevent="removeEmail(index)" class="btn btn-danger">Ta bort</button>
        </div>
        <button @click.prevent="addEmailField" class="btn btn-secondary mt-2">Lägg till e-post</button>
      </div>

      <!-- Lägg till ytterligare fält vid behov, som bilduppladdning eller andra specifika fält för ditt minne -->

      <button type="submit" class="btn btn-primary">Uppdatera Minne</button>
    </form>

    <button class="btn btn-danger mt-3" @click="deleteMinne">Radera Inlägg</button>
  </div>
</template>
  
  
<script>
import { db } from '@/firebaseConfig';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { useRouter } from 'vue-router';

export default {
  name: 'EditMinne',
  props: ['id'],
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      datum: '',
      rubrik: '',
      beskrivning: '',
      deltagare: '',
      sharedUsers: [],
      feedbackMessage: '',
      loading: true,
    };
  },
  async created() {
    try {
      const minneDocRef = doc(db, 'minnen', this.id);
      const minneDoc = await getDoc(minneDocRef);
      if (minneDoc.exists()) {
        const minneData = minneDoc.data();
        this.datum = minneData.datum;
        this.rubrik = minneData.rubrik;
        this.beskrivning = minneData.beskrivning;
        this.deltagare = minneData.deltagare.join(', ');
        this.sharedUsers = minneData.sharedUsers || [];
      } else {
        console.log('Minne finns inte!');
      }
    } catch (error) {
      console.error('Ett fel uppstod vid hämtning av minnet:', error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    addEmailField() {
      this.sharedUsers.push('');
    },
    removeEmail(index) {
      this.sharedUsers.splice(index, 1);
    },
    async updateMinne() {
      try {
        const minneDocRef = doc(db, 'minnen', this.id);
        await updateDoc(minneDocRef, {
          datum: this.datum,
          rubrik: this.rubrik,
          beskrivning: this.beskrivning,
          deltagare: this.deltagare.split(','),
          sharedUsers: this.sharedUsers
        });
        this.feedbackMessage = 'Minnet har uppdaterats!';
        this.router.push(`/visaminnen/${this.id}`);
      } catch (error) {
        console.error('Ett fel uppstod vid uppdatering:', error);
        this.feedbackMessage = 'Ett fel uppstod, försök igen.';
      }
    },
    async deleteMinne() {
      try {
        await deleteDoc(doc(db, 'minnen', this.id));
        this.router.push('/listaminnen');
      } catch (error) {
        console.error('Ett fel uppstod vid radering:', error);
      }
    }
  }
};
</script>
  

  
<style scoped>
/* Egna stilar här */
.darkerbackground {
  background-color: rgba(196, 201, 198, 0.686);
  padding: 30px;
  border-radius: 8px;
}
</style>
  