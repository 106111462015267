
<template>
 <section class="py-5 mt-5">
  
        <div class="container py-4 py-xl-5">
          
            <div class="row gy-4 gy-md-0">
                <div class="col-md-6 text-center text-md-start d-flex d-sm-flex d-md-flex justify-content-center align-items-center justify-content-md-start align-items-md-center justify-content-xl-center">
                    <div style="max-width: 350px;">
                        <h1 class="display-6 fw-bold mb-4 my-font">Din digitala dagbok för att minnas livets stora och små&nbsp;<span class="underline">händelser</span></h1>
                    </div>
                </div>
                <div class="col-md-6">
                    <div><img class="rounded img-fluid w-100 fit-cover" style="max-height: 400px; max-width: 400px;" src="@/assets/img/familj.jpg"></div>
                </div>
            </div>
            <div class="text-center mt-5">
              
                <p class="mb-4 my-font" style="font-size: 1.6rem;">Just nu endast för&nbsp;<span class="underline"><strong>speciellt</strong></span>&nbsp;inbjudna personer.</p><a href="#"> </a><a href="#"> </a><a href="#"> </a><a href="#"> </a><a href="#"> </a><a href="#"> </a>
            </div>
        </div>
    </section>
    <section>
        <div class="container py-4 py-xl-5">
            <div class="row mb-5">
                <div class="col-md-8 col-xl-6">
                    <h3 class="display-6 fw-bold pb-4 mb-4 my-font">Varje stund förtjänar mer än att bara vara en bild i din&nbsp;<span class="underline">kamerarulle</span></h3>
                </div>
                <div class="col-md-8 col-xl-6 pt-4">
                    <p class="text-muted my-font" style="font-size: 22px;">Tidskapseln.se ger dina minnen en plats att leva och andas. Här är de inte bara bilder - de är berättelser, känslor och erfarenheter.</p>
                </div>
            </div>
            <div class="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3">
                <div class="col">
                    <div class="card border-light border-1 d-flex justify-content-center p-4">
                        <div class="card-body">
                            <div>
                                <div class="ikon-color bs-icon-lg bs-icon-rounded bs-icon-secondary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-4 bs-icon"><svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-tools-kitchen">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M4 3h8l-1 9h-6z"></path>
                                        <path d="M7 18h2v3h-2z"></path>
                                        <path d="M20 3v12h-5c-.023-3.681.184-7.406 5-12z"></path>
                                        <path d="M20 15v6h-1v-3"></path>
                                        <line x1="8" y1="12" x2="8" y2="18"></line>
                                    </svg></div>
                                <h4 class="fw-bold my-font">Funktioner</h4>
                                <p class="text-muted my-font">Upptäck kraften i att bevara varje speciell stund med Tidskapseln.se. Vår app är mer än bara en plats för att lagra dina foton - det är en hel värld av minnen, väntar på att bli fyllda med livets rikedomar.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card border-light border-1 d-flex justify-content-center p-4">
                        <div class="card-body">
                            <div>
                                <div class="ikon-color bs-icon-lg bs-icon-rounded bs-icon-secondary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-4 bs-icon"><svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-accessible">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <circle cx="12" cy="12" r="9"></circle>
                                        <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1"></path>
                                        <circle cx="12" cy="7.5" r=".5" fill="currentColor"></circle>
                                    </svg></div>
                                <h4 class="fw-bold my-font">Perfekt</h4>
                                <p class="text-muted my-font">Tidskapseln.se är den perfekta platsen för alla som vill hålla sina minnen organiserade och lättillgängliga. Perfekt för familjefester, semestrar, personliga milstolpar, och allt däremellan. Det är ditt livs bibliotek, alltid inom räckhåll.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card border-light border-1 d-flex justify-content-center p-4">
                        <div class="card-body">
                            <div>
                                <div class="ikon-color bs-icon-lg bs-icon-rounded bs-icon-secondary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-4 bs-icon"><svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-question-mark">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4"></path>
                                        <line x1="12" y1="19" x2="12" y2="19.01"></line>
                                    </svg></div>
                                <h4 class="fw-bold my-font">Varför Tidskapseln?</h4>
                                <p class="text-muted my-font">Varje stund förtjänar mer än att bara vara en bild i din kamerarulle. Tidskapseln.se ger dina minnen en plats att leva och andas. Här är de inte bara bilder - de är berättelser, känslor och erfarenheter.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-5">
        <div class="container">
            <div class="row">
                <div class="col-md-4 mb-5"><img class="rounded img-fluid" src="@/assets/img/skidor.jpg"></div>
                <div class="col d-md-flex align-items-md-end align-items-lg-center mb-5">
                    <div class="row gy-4 row-cols-1 row-cols-md-2 flex-grow-1">
                        <div class="col">
                            <div class="card border-light border-1 d-flex justify-content-center p-4">
                                <div class="card-body">
                                    <div>
                                        <h4 class="fw-bold my-font">Gå bortom bilden</h4>
                                        <p class="text-muted d-xl-block my-font">Lägg till lager av betydelse med titlar, beskrivningar och deltagarlistor.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card border-light border-1 d-flex justify-content-center p-4">
                                <div class="card-body">
                                    <div>
                                        <h4 class="fw-bold my-font">Dela säkert</h4>
                                        <p class="text-muted d-xl-block my-font">Till skillnad från sociala medier, bestämmer du vem som ser dina minnen utan att kompromissa med din integritet.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col d-md-flex align-items-md-end align-items-lg-center mb-5">
                    <div class="row gy-4 row-cols-1 row-cols-md-2 flex-grow-1">
                        <div class="col">
                            <div class="card border-light border-1 d-flex justify-content-center p-4">
                                <div class="card-body">
                                    <div>
                                        <h4 class="fw-bold my-font">Säker backup</h4>
                                        <p class="text-muted d-xl-block my-font">Med allt säkert lagrat i molnet, finns dina minnen säkert bevarade även om telefonen går förlorad eller blir skadad.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card border-light border-1 d-flex justify-content-center p-4">
                                <div class="card-body">
                                    <div>
                                        <h4 class="fw-bold my-font">Inte bara en app</h4>
                                        <p class="text-muted d-xl-block my-font">Tidskapseln.se är inte bara en app - det är en investering i ditt familjearv. Börja bygga din digitala minnesbok idag och se till att varje värdefull ögonblick bevaras för framtiden.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 order-first order-md-last mb-5"><img class="rounded img-fluid" src="@/assets/img/brollop.jpg"></div>
                
            </div>
            <LogIn v-if="!isUserLoggedIn"></LogIn>
        </div>
    </section>
<div>
    
  </div>
</template>








<style scoped>
/* Dina befintliga stilar */
</style>




<script>
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import LogIn from '@/components/LogIn.vue';

export default {
  name: 'HomeView',
  components: {
    LogIn
  },
  data() {
    return {
      isUserLoggedIn: false
    };
  },
  created() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      this.isUserLoggedIn = !!user;
    });
  }
}
 
</script>


<style scoped>
.home-view {
  padding: 3rem 0;
  /*background: url('path-to-your-background-image.jpg') no-repeat center center; */
  background-size: cover;
}

.banner {
  padding: 4rem 0;
  background: rgba(0, 0, 0, 0.5); /* Lägger till en mörk övertoning för bättre läsbarhet */
}

.logo {
  max-width: 10%;
  margin-bottom: 1rem;
}

.title {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.ikon-color {
  color: rgb(255, 200, 0);
}

.my-font {
  font-family: 'Kdam Thmor Pro', sans-serif;
}




.underline {
  text-decoration: none; /* Tar bort den vanliga understrykningen */
  border-bottom: 10px solid rgb(255, 255, 0); /* Skapar en fet, gul linje under texten */
  display: inline-block; /* Säkerställer att gränsen endast sträcker sig under texten */
  padding-bottom: 2px; /* Lite utrymme mellan texten och strecket */
}





/* Lägg till ytterligare stiljusteringar efter behov */
</style>
