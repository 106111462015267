
import { createRouter, createWebHashHistory } from 'vue-router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import HomeView from '../views/HomeView.vue';
import LogIn from '../components/LogIn';
import ListaMinnen from '../components/ListaMinnen';
import VisaMinnen from '../components/VisaMinne';
import AboutView from '../views/AboutView';
import DelaMinnen from '../components/DelaMinnen';
import EditMinne from '../components/EditMinne';
import EditImage from '../components/EditImage';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/Login',
    name: 'login',
    component: LogIn
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
   
    
  },
  {
    path: '/listaminnen',
    name: 'listaminnen',
    component: ListaMinnen
    
  },
  {
    path: '/visaminnen/:id', // Lägg till en route med parameter för id
    name: 'visaminnen',
    component: VisaMinnen,
    props: true // Tillåter att route-parametrar skickas som props
  },
  {
    path: '/delaminnen',
    name: 'delaminnen',
    component: DelaMinnen
    
  },
  {
    path: '/editminne/:id',
    name: 'editminne',
    component: EditMinne,
    props: true // Tillåter att route-parametrar skickas som props
    
  },
  {
    path: '/editimage/:id',
    name: 'EditImage',
    component: EditImage,
    props: true
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// Lägg till navigeringsvakt för att hantera åtkomst baserat på autentiseringsstatus
router.beforeEach((to, from, next) => {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    const isPublicPage = to.name === 'home' || to.name === 'about';
    
    if (!user && !isPublicPage) {
      // Om användaren inte är inloggad och sidan inte är public, navigera till home
      next({ name: 'home' });
    } else {
      // I alla andra fall, fortsätt med nuvarande navigering
      next();
    }
  });
});

export default router;

