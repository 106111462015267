<template>
  <div class="login-container">
    <form @submit.prevent="signInWithGoogle">
      <button type="submit" class="google-login-btn" :disabled="loading">
        <img v-if="!loading" src="@/assets/img/brands/google-logo.png" alt="Google Logo" class="google-logo">
        <span v-if="loading">Loggar in...</span>
        <span v-else>Logga in med Google</span>
      </button>
    </form>
  </div>
</template>

<script>
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '@/firebaseConfig';
import { useRouter } from 'vue-router';
import { ref } from 'vue';

export default {
  name: 'LogIn',
  setup() {
    const router = useRouter();
    const loading = ref(false); // Använd 'ref' för att göra 'loading' reaktiv

    const signInWithGoogle = async () => {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      try {
        loading.value = true; // Aktivera laddningsindikator
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        console.log('Inloggning lyckades:', user.uid);

        // Kontrollera och skapa användardokument om det inte finns
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (!userDoc.exists()) {
          await setDoc(userDocRef, {
            email: user.email,
            sharedUsers: []
          });
          console.log('Användardokument skapat för:', user.email);
        }

        router.push('/listaminnen'); // Navigera till ListaMinnen.vue
      } catch (error) {
        console.error(error);
        alert('Inloggningsfel: ' + error.message);
      } finally {
        loading.value = false; // Stoppa laddningsindikator
      }
    };

    return { signInWithGoogle, loading };
  }
}
</script>



  
  
  
<style scoped>
.login-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
}
.google-login-btn {
  background-color: #4285F4;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Avstånd mellan ikon och text */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25); /* Lätt skugga för djup */
  transition: background-color 0.3s;
}

.google-login-btn:hover {
  background-color: #357ae8; /* Mörkare nyans vid hover */
}

.google-login-btn:active {
  background-color: #3367D6; /* Ännu mörkare nyans vid klick */
}

.google-logo {
  width: 18px; /* Eller storleken på din ikon */
  height: auto;
}

</style>
  