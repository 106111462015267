<template>
  <nav class="navigation-links">
    <router-link to="/">Hem</router-link> |
    <router-link to="/about">Om oss</router-link> <span v-if="isLoggedIn">|</span>
    <router-link v-if="isLoggedIn" to="/listaminnen"> Lista Minnen </router-link> <span v-if="isLoggedIn">|</span>
    <router-link v-if="isLoggedIn" to="/delaminnen"> Dela </router-link>
    <button v-if="isLoggedIn" @click="loggaUt">Logga ut</button>
  </nav>
  <div v-if="!isLoggedIn" class="login-container">
    <LogIn></LogIn>
  </div>
  <transition name="fade" mode="out-in">
    <router-view />
  </transition>
</template>


<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import LogIn from '@/components/LogIn.vue';

export default {
  name: 'App',
  components: {
    LogIn
  },
  setup() {
    const router = useRouter();
    const auth = getAuth();
    const isLoggedIn = ref(false); // Lägger till en reaktiv referens för att spåra inloggningsstatus

    onAuthStateChanged(auth, user => {
      isLoggedIn.value = !!user; // Uppdatera isLoggedIn baserat på om en användare är inloggad
    });

    const loggaUt = async () => {
      try {
        await signOut(auth);
        router.push('/');
      } catch (error) {
        console.error('Utloggningen misslyckades:', error);
      }
    };

    return { loggaUt, isLoggedIn };
  }
};
</script>





<style>
/* Lägg till övergångsstilar här */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.7s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
/* Media query för små skärmar (mobila enheter) */
@media (max-width: 600px) {

  /* Ändra 600px till det värde som passar bäst för din design */
  .navigation-links {
    margin-bottom: 15px;
    /* Lägger till mellanrum under menyn på små skärmar */
  }

  .navigation-links button {
    display: block;
    /* Gör knappen blocknivå för att försäkra att den tar upp hela raden */
    width: 100%;
    /* Gör knappen full bredd */
    margin-top: 10px;
    /* Lägger till utrymme ovanför knappen */
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f2f2f2;
  /* Din bakgrundsfärg */
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: rgb(0, 0, 0);
  /* Sätt textfärgen till vit */
  text-decoration: none;
  /* Ta bort textdekoration */
  font-family: 'Kdam Thmor Pro', sans-serif;
  /* Ange 'Kdam Thmor Pro' som typsnitt */
}

nav a.router-link-exact-active,
nav a.router-link-active {
  color: rgb(0, 0, 0);
  /* Sätt den aktiva länkens färg till vit (eller annan färg om du föredrar) */
  font-family: 'Kdam Thmor Pro', sans-serif;
  /* Ange 'Kdam Thmor Pro' som typsnitt */
}

/* Globala stilar */
body,
html {
  height: 100%;
  margin: 0;
}


.login-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  /* Eller vilket utrymme du behöver */
}</style>

