<template>
  <div class="container py-4">
    <div class="minne-container text-center">
      <h2 class="minne-title">{{ rubrik }}</h2>
      <p class="text-muted minne-date">{{ datum }}</p>
      <p class="minne-description">{{ beskrivning }}</p>
      <h3 class="minne-heading">Deltagare</h3>
      <p class="minne-participants">{{ deltagare }}</p>
    </div>
    <div class="row minne-images">
      <div class="col-md-4 mb-3" v-for="bild in bilder" :key="bild">
        <img :src="bild" class="img-fluid rounded-image" :alt="'Bild ' + bild">
      </div>
    </div>
  </div>
</template>

<script>
import { db } from '@/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

export default {
  name: 'VisaMinne',
  props: ['id'], // Ta emot 'id' som en prop
  data() {
    return {
      rubrik: '',
      datum: '',
      beskrivning: '',
      deltagare: '',
      bilder: []
    };
  },
  async created() {
    try {
      const minneDocRef = doc(db, 'minnen', this.id);
      const minneDoc = await getDoc(minneDocRef);

      if (minneDoc.exists()) {
        const minneData = minneDoc.data();
        this.rubrik = minneData.rubrik;
        this.datum = minneData.datum;
        this.beskrivning = minneData.beskrivning;
        this.deltagare = minneData.deltagare.join(', ');
        this.bilder = minneData.bilder;
      } else {
        console.log('Minne finns inte!');
      }
    } catch (error) {
      console.error('Ett fel uppstod vid hämtning av minnet:', error);
    }
  },
  methods: {
    openImageInNewTab(imageUrl) {
      window.open(imageUrl, '_blank');
    }
  }
};
</script>

<style scoped>
.minne-container {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.minne-title {
  margin-bottom: 1rem;
  color: #0a090a;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-family: 'Kdam Thmor Pro', sans-serif;
  font-weight: 900;
}

.minne-date {
  color: #ccc;
  margin-bottom: 1rem;
  font-family: 'Kdam Thmor Pro', sans-serif;
}

.minne-description,
.minne-participants {
  color: #1f1d1d;
  margin-bottom: 1rem;
  font-family: 'Kdam Thmor Pro', sans-serif;
}

.minne-heading {
  color: #000000;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: 'Kdam Thmor Pro', sans-serif;
}

.rounded-image {
  border-radius: 15px;
  padding: 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-height: 300px;
  width: auto;
  max-width: 100%;
}

/* Media query för skrivbordsmiljöer */
@media (min-width: 1024px) {
  .rounded-image:hover {
    transform: scale(2.5);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}
</style>


  