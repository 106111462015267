<template>
  <div class="container py-4">

    

    <h2 class="mb-4">Skapa Nytt Minne</h2>
    <!-- Lägg till ett fält för att ange e-postadresser för att dela minnet -->
  
    <form @submit.prevent="addMinne" class="needs-validation" novalidate ref="form">
      <div class="mb-3">
        <label for="datum" class="form-label vit-text">Datum</label>
        <input type="date" class="form-control" id="datum" v-model="datum" required>
        <div class="invalid-feedback">
          Ange ett datum.
        </div>
      </div>

      <div class="mb-3">
        <label for="rubrik" class="form-label vit-text">Rubrik</label>
        <input type="text" class="form-control" id="rubrik" v-model="rubrik" required>
        <div class="invalid-feedback">
          Ange en rubrik.
        </div>
      </div>

      <div class="mb-3">
        <label for="beskrivning" class="form-label vit-text">Beskrivning</label>
        <textarea class="form-control" id="beskrivning" rows="3" v-model="beskrivning" required></textarea>
        <div class="invalid-feedback">
          Ange en beskrivning.
        </div>
      </div>

      <div class="mb-3">
        <label for="deltagare" class="form-label vit-text">Deltagare</label>
        <input type="text" class="form-control" id="deltagare" v-model="deltagare" required
          placeholder="Namn, separera med komma">
        <div class="invalid-feedback">
          Ange deltagare.
        </div>
      </div>

      <div class="mb-3">
        <div v-if="uploadProgress > 0 && uploadProgress < 100" class="progressbar">
          Uppladdar: {{ uploadProgress.toFixed(0) }}%
        </div>
        <label for="bilder" class="form-label vit-text">Bilder</label>
        <input type="file" class="form-control" id="bilder" multiple @change="handleFileUpload">
        <div class="form-text vit-text">Max 20 bilder</div>
      </div>
      <div class="mb-3">
    <label for="sharedUsers" class="form-label">Dela med (gmail-adresser) enskild delning.</label>
    <input type="text" class="form-control" id="sharedUsers" v-model="sharedUsers" placeholder="Ange e-postadresser, separera med komma">
  </div>

      <button type="submit" class="btn btn-primary">Skapa Minne</button>
    </form>
    <div v-if="feedbackMessage" class="alert alert-success mt-3">{{ feedbackMessage }}</div>
  </div>
</template>

<script>
import { db, auth, storage } from '@/firebaseConfig';
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useRouter } from 'vue-router';

export default {
  name: 'SkapaMinneForm',
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      datum: '',
      rubrik: '',
      beskrivning: '',
      deltagare: '',
      bilder: [],
      feedbackMessage: '',
      uploadProgress: 0,
    };
  },
  methods: {
    handleFileUpload(event) {
      const files = Array.from(event.target.files);
      if (files.length > 20) {
        alert("Du kan bara ladda upp max 20 bilder.");
        return;
      }
      this.bilder = files;
    },
    async uploadImages() {
      const imageUploadPromises = this.bilder.map(file => {
        const storageReference = storageRef(storage, `bilder/${Date.now()}-${file.name}`);
        const uploadTask = uploadBytesResumable(storageReference, file);

        return new Promise((resolve, reject) => {
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              this.uploadProgress = progress;
            },
            reject,
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            }
          );
        });
      });

      return Promise.all(imageUploadPromises);
    },
    async addMinne() {
      if (!this.$refs.form.checkValidity()) {
        this.$refs.form.classList.add('was-validated');
        return;
      }

      if (!auth.currentUser) {
        this.feedbackMessage = 'Du måste vara inloggad för att lägga till ett minne.';
        return;
      }

      try {
        const imageUrls = await this.uploadImages();
        const usersDocRef = doc(db, 'users', auth.currentUser.uid);
        const usersDoc = await getDoc(usersDocRef);
        let sharedUsers = [];
        if (usersDoc.exists()) {
          sharedUsers = usersDoc.data().sharedUsers || [];
        }

        const docRef = await addDoc(collection(db, 'minnen'), {
          datum: this.datum,
          rubrik: this.rubrik,
          beskrivning: this.beskrivning,
          deltagare: this.deltagare.split(','),
          bilder: imageUrls,
          userId: auth.currentUser.uid,
          userName: auth.currentUser.displayName || auth.currentUser.email,
          sharedUsers: sharedUsers
        });
        this.feedbackMessage = 'Ditt minne har sparats!';
        this.clearForm();
        this.$router.push(`/visaminnen/${docRef.id}`);
        setTimeout(() => {
          this.feedbackMessage = '';
        }, 3000);
      } catch (error) {
        console.error('Ett fel uppstod vid inskick:', error);
        this.feedbackMessage = 'Ett fel uppstod, försök igen.';
      }
    },
    clearForm() {
      this.datum = '';
      this.rubrik = '';
      this.beskrivning = '';
      this.deltagare = '';
      this.bilder = [];
      this.feedbackMessage = '';
      if (this.$refs.form) {
        this.$refs.form.classList.remove('was-validated');
      }
    }
  }
};
</script>






<style scoped>
/* Egna anpassade stilar om det behövs */
.was-validated .form-control:invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
/* Lägg till stil för progressbar */
.progressbar {
  background-color: green;
  color: white;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  text-align: center;
}
h1, h2, h3, h4, h5, h6{
  color: rgb(70, 69, 69) !important; /* Använd !important för att överskrida Bootstrap-standarder */

}
.vit-text{
  color: rgb(90, 90, 90) !important; /* Använd !important för att överskrida Bootstrap-standarder */
}
</style>



