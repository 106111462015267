<template>
  <div class="about-view py-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 mx-auto text-center">
          <h1 class="display-4">Om Tidskapseln.se</h1>
          <p class="lead mt-4">
            Tidskapseln.se är en digital plattform skapad för att bevara dina värdefulla minnen. Vår mission är att göra det enklare och roligare att spara och dela dina minnen med nära och kära.
          </p>
          <p class="lead mt-4">
            Bakom Tidskapseln står Patrik Hyllman, en passionerad apputvecklare med många års erfarenhet. Patrik har en vision om att göra digital minnesbevaring tillgänglig och meningsfull för alla.
          </p>
        </div>
      </div>
      <div class="row mt-5">
        <!-- Bootstrap cards med rundade bilder och skugga -->
        <div class="col-md-4 text-center my-3">
          <div class="card border-0 h-100">
            <img src="@/assets/img/placeholder1.jpg" class="card-img-top rounded-circle p-4" alt="Vision">
            <div class="card-body">
              <h3 class="card-title">Vår Vision</h3>
              <p class="card-text">
                Vi tror på att varje ögonblick är värt att bevaras. Tidskapseln.se är din personliga tidslinje, där varje foto och berättelse får sin plats att lysa.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 text-center my-3">
          <div class="card border-0 h-100">
            <img src="@/assets/img/placeholder2.jpeg" class="card-img-top rounded-circle p-4" alt="Mission">
            <div class="card-body">
              <h3 class="card-title">Vår Mission</h3>
              <p class="card-text">
                Att erbjuda en säker och lättanvänd plattform där du kan samla alla dina minnen, från stora händelser till små vardagsögonblick.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 text-center my-3">
          <div class="card border-0 h-100">
            <img src="@/assets/img/placeholder3.jpeg" class="card-img-top rounded-circle p-4" alt="Värderingar">
            <div class="card-body">
              <h3 class="card-title">Våra Värderingar</h3>
              <p class="card-text">
                Integritet, användarvänlighet och minnenas livslängd står i centrum för allt vi gör. Vi värdesätter ditt förtroende och dina minnen.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.about-view {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f8f9fa; /* En ljus bakgrundsfärg */
}

.display-4 {
  font-weight: 700;
  color: #1f1e20; /* En färg som matchar din webbplats tema */
}

.rounded-circle {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.card {
  background-color: #fff;
  border-radius: 15px; /* Rundade hörn för kort */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Lägg till en subtil skugga */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* För hover-effekter */
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15); /* Förstärkt skugga vid hover */
}

.card-img-top {
  width: 140px; /* Anpassa storleken på din bild */
  height: 140px; /* Anpassa storleken på din bild */
  border: 3px solid #fff; /* En vit kant runt bilden */
  margin-top: -70px; /* För att flytta upp bilden */
  background-color: #fff; /* Bakgrundsfärgen bakom bilden */
}

.card-body {
  padding-top: 70px; /* Ger utrymme för bilden att gå in i kortets kropp */
}

.card-title {
  color: #4b494e; /* Titelfärgen som matchar din webbplats tema */
}

.card-text {
  color: #333; /* Textfärg för bra kontrast */
}
</style>



