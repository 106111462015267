import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Lägg till denna rad

const firebaseConfig = {
    apiKey: "AIzaSyCpV5UV5_qnC9iE4LzsWo27ic9yb9rAKIE",
    authDomain: "thingswedo-c3e11.firebaseapp.com",
    projectId: "thingswedo-c3e11",
    storageBucket: "thingswedo-c3e11.appspot.com",
    messagingSenderId: "151282071216",
    appId: "1:151282071216:web:c911c156b4ca961da4ed75"
};

// Initiera Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app); // Lägg till denna rad för att exportera storage
